<template>
  <div class="question_wrap">
    <van-form>
      <div class="question_top">
        <div class="question_top_field">
          <van-field
            class="question_out"
            v-model="formData.content"
            name="content"
            type="textarea"
            maxlength="150"
            placeholder="写下您遇到的问题..."
            show-word-limit
          >
          </van-field>
        </div>
      </div>
      <div class="question_tel">
        <van-field
          v-model="formData.phone"
          name="phone"
          placeholder="请留下您的手机号，以便我们联系您"
        >
        </van-field>
      </div>
      <div class="question_bottom">
        <van-button type="info" size="large" round @click="onSubmit"
          >提交反馈</van-button
        >
      </div>
    </van-form>
  </div>
</template>
<script>
export default {
  data() {
    return {
      fileLocal: require("../assets/images/positive.png"),
      fileList: [],
      formData: {
        content: "",
        phone: "",
      },
      access_token: "",
      realname: "",
      pid: "",
    };
  },
  mounted() {
    let access_token = localStorage.getItem("access_token");
    this.access_token = access_token;
    let realname = localStorage.getItem("realname");
    this.realname = realname;
    let pid = localStorage.getItem("pid");
    this.pid = pid;
    // if (!this.pid) {
    //   this.$dialog
    //     .alert({
    //       message: "拒绝访问！",
    //       showConfirmButton: false,
    //     })
    //     .then(() => {});
    // }
  },
  methods: {
    onSubmit() {
      let _this = this;
      let realname = localStorage.getItem("realname");
      this.formData.realname = realname;
      this.formData.status = 0;
      if (!this.formData.content) {
        this.$toast({ message: "请填写反馈内容" });
      } else if (!this.formData.phone) {
        this.$toast({ message: "请填写手机号" });
      } else {
        this.$axios
          .post("/message/edit", this.formData, {
            headers: {
              "content-type": "application/json",
              Authorization: this.access_token,
            },
          })
          .then((res) => {
            if (res.data.success) {
              this.$toast({ message: "反馈已提交，静候佳音~" });
              setTimeout(function () {
                _this.$router.push({ name: "mine" });
              }, 1000);
            } else {
              this.$toast({ message: res.data.msg });
            }
          })
          .catch(() => {});
      }
    },
  },
};
</script>
<style scoped>
.question_img_text {
  text-align: center;
  line-height: 1.2rem;
}
.question_top_field {
  border-bottom: 0.0625rem solid #eee;
  margin-top: 1rem;
}
.question_center {
  display: flex;
  justify-content: space-between;
  height: 5rem;
  border-bottom: 0.0625rem solid #eee;
  padding-left: 1rem;
  padding-right: 1rem;
}
</style>
<style>
.van-toast__text {
  line-height: 2rem;
  font-size: 1rem;
  text-align: center;
}

.question_out .van-field__control {
  padding-top: 0.5rem;
  height: 8rem;
}

.question_left {
  line-height: 5rem;
}
.question_img_li {
  width: 4rem;
  height: 4rem;
}
.question_img {
  display: flex;
  align-items: center;
}
.question_img .van-uploader__preview-image {
  width: 4rem;
  height: 4rem;
}
.question_img .van-uploader__preview-delete {
  width: 1rem;
  height: 1rem;
  font-size: 1rem;
}
.question_tel {
  border: 0.0625rem solid #eee;
  width: calc(100% - 1rem);
  margin: 0 auto;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.question_tel .van-cell {
  line-height: 2.4rem;
  font-size: 1rem;
}
.question_top {
  width: calc(100% - 1rem);
  margin: 0 auto;
  border: 0.0625rem solid #eee;
  margin-top: 1rem;
}
.question_bottom {
  width: calc(100% - 1rem);
  margin: 0 auto;
}
.question_bottom .van-button {
  font-size: 1rem;
  height: 2rem;
  border-radius: 0.5rem;
}
</style>